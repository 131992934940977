<template>
  <div
    fill-height
    class="play-mode-wrap"
    :class="{
      ['play-mode-wrap--presenter']: isViewerPresenter
    }"
  >
    <v-flex
      :xs3="!enlargedUI"
      :xs9="enlargedUI && gameStatus.media"
      ml-2
      class="asset-column"
    >
      <v-layout column justify-center fill-height>
        <div class="play-asset-wrap">
          <AssetSlide class="play-asset" :mode="mode" />
        </div>
      </v-layout>
    </v-flex>

    <v-flex :xs6="!enlargedUI" mr-2 ml-2 class="play-mode">
      <div
        class="play-mode__countdown"
        v-if="isTimeAvailable"
        :class="{ 'play-mode__countdown--enlarged': enlargedUI }"
      >
        <PillCountdown :endTime="endTime" class="play-mode__countdown-timer" />
      </div>

      <MissionContainer :mode="mode" :mission="mission" :points="isMissionMeta">
        <Drawing
          v-if="isDrawingMission"
          :mission="mission"
          :key="`drawing-component-${missionID}`"
          :height="DRAWING_CANVAS_HEIGHT"
          :width="510"
          bg="#fff"
        />
        <!-- TODO - probably we should key all mossion components here -->
        <MultipleChoice
          v-if="missionType === MissionType.MultipleChoice"
          :key="transitionKey"
          :mode="mode"
          :mission="mission"
        />
        <Poll
          v-else-if="missionType === MissionType.Poll"
          :mode="mode"
          :mission="mission"
        />
        <RatingPoll
          v-else-if="missionType === MissionType.RatingPoll"
          :mode="mode"
          :mission="mission"
        />
        <Giphy
          v-else-if="missionType === MissionType.Giphy"
          :mode="mode"
          :mission="mission"
        />
        <Lipdub
          v-else-if="missionType === 'Lipdub'"
          :key="`lipdub-${missionID}`"
          :mode="mode"
          :mission="mission"
        />
        <TakePhoto
          v-else-if="
            [MissionType.TeamPhoto, MissionType.TakePhoto].includes(missionType)
          "
          :mode="mode"
          :mission="mission"
        />
        <Photo
          v-else-if="missionType === 'Photo'"
          :mode="mode"
          :mission="mission"
        />
        <URL
          v-else-if="missionType === MissionType.URL"
          :mode="mode"
          :mission="mission"
        />
        <Unlock
          v-else-if="missionType === 'Unlock'"
          :mode="mode"
          :mission="mission"
        />
        <SignUp v-else-if="isSignUp" :mode="mode" :mission="mission" />
        <TextInput
          v-else-if="
            missionType === 'Text' ||
            missionType === 'Freeform' ||
            missionType === 'Team Name' ||
            missionType === 'Fact Match'
          "
          :mode="mode"
          :mission="mission"
          :maxTextLength="missionType === 'Team Name' ? 30 : null"
        />
        <Categories
          v-else-if="missionType === MissionType.Categories"
          :mode="mode"
          :mission="mission"
        />
        <SurveySays
          v-else-if="missionType === MissionType.FamilyFeud"
          :mode="mode"
          :mission="mission"
        />
        <VideoMission
          v-else-if="missionType === MissionType.YouTube"
          :mission="mission"
        />
        <TextOnly
          v-else-if="isTextOnly"
          :mode="Mode.Explain"
          :mission="mission"
        />
        <Represent
          v-else-if="missionType === 'Represent'"
          :mode="mode"
          :mission="mission"
        />
        <RoyalRumble
          v-else-if="missionType === 'Royal Rumble'"
          :mode="mode"
          :mission="mission"
        />
        <Slides
          v-else-if="missionType === MissionType.Slides"
          :mission="mission"
        />
        <PhotoBooth
          v-else-if="missionType === MissionType.PhotoBooth"
          :mode="mode"
          :mission="mission"
        />
        <TwoTruths
          v-else-if="missionType === 'Two Truths'"
          :mode="mode"
          :mission="mission"
        />
        <AwardVote
          v-else-if="missionType === 'Award'"
          :mode="mode"
          :mission="mission"
        />
        <StartButton
          v-else-if="isStartButton || isOpenWeveStart"
          :mode="mode"
          :mission="mission"
        />
        <QRCode v-else-if="missionType === MissionType.QRCode" />
        <TakeVideo v-else-if="isTakeVideo" :mode="mode" :mission="mission" />
      </MissionContainer>

      <div
        v-if="skipButton"
        class="play-mode__wrap skip-video"
        @click="handleSkipVideo()"
        style="cursor: pointer"
      >
        <RtbPillPurple> SKIP VIDEO </RtbPillPurple>
      </div>
    </v-flex>
    <v-flex d-flex align-center :xs3="!enlargedUI" mr-2>
      <transition name="canvas-tools-slide-transition" mode="out-in">
        <DrawingPicker v-if="hasPallette" />
      </transition>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import useEnlargedUI from "@/use/useEnlargedUI"
import NavigationService from "@/services/navigation.service"

import AssetSlide from "@/components/GroupTeams/Common/AssetSlide.vue"
import PillCountdown from "@/components/GroupTeams/Common/PillCountdown"

const MultipleChoice = () =>
  import("@/components/GroupTeams/Common/Games/MultipleChoice.vue")
const Poll = () => import("@/components/GroupTeams/Common/Games/Poll.vue")
const Giphy = () => import("@/components/GroupTeams/Common/Games/Giphy.vue")
const Lipdub = () => import("@/components/GroupTeams/Common/Games/Lipdub.vue")
const TextInput = () =>
  import("@/components/GroupTeams/Common/Games/TextInput.vue")
const SignUp = () => import("@/components/GroupTeams/Common/Games/SignUp.vue")
const Unlock = () => import("@/components/GroupTeams/Common/Games/Unlock.vue")
const TextOnly = () =>
  import("@/components/GroupTeams/Common/Games/TextOnly.vue")
const Categories = () =>
  import("@/components/GroupTeams/Common/Games/Categories/Categories.vue")
const SurveySays = () =>
  import("@/components/GroupTeams/Common/Games/SurveySays.vue")
const TakePhoto = () =>
  import("@/components/GroupTeams/Common/Games/TakePhoto.vue")
const Photo = () => import("@/components/GroupTeams/Common/Games/Photo.vue")
const URL = () => import("@/components/GroupTeams/Common/Games/Url.vue")
const MissionContainer = () =>
  import("@/components/GroupTeams/Common/Games/MissionContainer.vue")
const Drawing = () => import("@/components/GroupTeams/Common/Games/Drawing.vue")
const Represent = () =>
  import("@/components/GroupTeams/Common/Games/Represent.vue")
const RoyalRumble = () =>
  import("@/components/GroupTeams/Common/Games/RoyalRumble.vue")
const PhotoBooth = () =>
  import("@/components/GroupTeams/Common/Games/PhotoBooth.vue")
const TwoTruths = () =>
  import("@/components/GroupTeams/Common/Games/TwoTruths.vue")
const Slides = () =>
  import("@/components/GroupTeams/Common/Games/Slide/Slide.vue")
const AwardVote = () =>
  import("@/components/GroupTeams/Common/Games/AwardVote.vue")
const TakeVideo = () =>
  import("@/components/GroupTeams/Common/Games/TakeVideo.vue")
const StartButton = () =>
  import("@/components/GroupTeams/Common/Games/StartButton.vue")
const VideoMission = () =>
  import("@/components/GroupTeams/Common/Games/VideoMission")
const DrawingPicker = () =>
  import("@/components/GroupTeams/Common/GameModes/DrawingPicker.vue")
const QRCode = () =>
  import("@/components/GroupTeams/Common/Games/QRCode/QRCode.vue")

const COUNTDOWN_START_MODES = [Mode.Play, Mode.Huddle, Mode.Voting, Mode.Social]

const TEXT_ONLY_MISSION_TYPES = [
  MissionType.SpiralDrawing,
  MissionType.BuzzIn,
  MissionType.PriceIsRight,
  MissionType.Info,
  MissionType.MatchGame,
  MissionType.OrderTheCards,
  MissionType.Link,
  MissionType.OrderTheCards,
  MissionType.TwoTruthsReveal,
  MissionType.Slides,
  MissionType.HandMovement,
  MissionType.GameOver
]

const TAKE_VIDEO_MISSION_TYPES = [
  MissionType.Video,
  MissionType.VideoTeam,
  MissionType.VideoIndividual
]

import MissionType from "@shared/enums/Mission"
import Mode from "@shared/enums/Mode"
import Mission from "@shared/Mission"

import {
  NotificationMixin,
  NotificationType,
  NotificationScope
} from "@/mixins/NotificationMixin"

export default {
  name: "PlayMode",
  mixins: [NotificationMixin],
  components: {
    AssetSlide,
    PillCountdown,
    Poll,
    RatingPoll: () =>
      import("@/components/GroupTeams/Common/Games/RatingPoll/RatingPoll.vue"),
    MultipleChoice,
    TextInput,
    SignUp,
    Unlock,
    TextOnly,
    Giphy,
    MissionContainer,
    Categories,
    Lipdub,
    TakePhoto,
    DrawingPicker,
    Photo,
    URL,
    QRCode,
    Drawing,
    Represent,
    PhotoBooth,
    Slides,
    TwoTruths,
    AwardVote,
    RoyalRumble,
    StartButton,
    VideoMission,
    TakeVideo
  },
  props: {
    mode: {
      type: String,
      required: false
    }
  },
  setup() {
    const { enlargedUI } = useEnlargedUI()
    return { enlargedUI }
  },
  data() {
    return {
      Mode,
      MissionType,
      DRAWING_CANVAS_HEIGHT: 270
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "user"]),
    ...mapGetters(["game", "orgID", "gameID", "gameStatus"]),
    ...mapGetters("group", ["isViewerPresenter"]),
    transitionKey() {
      return this.mission?.activityId == null
        ? this.mission?.id
        : this.mission?.activityId
    },
    isTakeVideo() {
      return TAKE_VIDEO_MISSION_TYPES.includes(this.missionType)
    },
    skipButton() {
      return (
        this.game.diy &&
        this.mode !== Mode.Results &&
        ((this.missionType === MissionType.Info && this.mission.youtube) ||
          this.missionType === MissionType.YouTube)
      )
    },
    isStartButton() {
      return this.missionType === MissionType.StartButton
    },
    isOpenWeveStart() {
      return this.missionType === MissionType.OpenWeveStart
    },
    isMissionMeta() {
      return Boolean(
        this.mission &&
          this.mode !== Mode.Results &&
          this.missionType !== MissionType.YouTube &&
          this.missionType !== MissionType.OpenWeveStart &&
          this.missionType !== MissionType.StartButton
      )
    },
    isTimeAvailable() {
      if (!this.isMissionMeta) return false
      if (this.game?.autopilot) {
        return (
          this.isHost ||
          (COUNTDOWN_START_MODES.includes(this.mode) && this.endTime > 0)
        )
      } else {
        return (
          COUNTDOWN_START_MODES.includes(this.mode) && this.mission.time > 0
        )
      }
    },
    endTime() {
      const int = parseInt(this.gameStatus.endTime) || 0
      const endTime = int - Date.now()
      return endTime > 0 ? endTime : 0
    },
    isDrawingMission() {
      return Mission.isDrawing(this.mission)
    },
    missionType() {
      return this.mission?.behavior
    },
    missionID() {
      return this.mission?.id
    },
    mission() {
      return this.$store.getters.getCurrentMission
    },
    isSignUp() {
      return this.mission.signup
    },
    isTextOnly() {
      return TEXT_ONLY_MISSION_TYPES.includes(this.missionType)
    },
    hasPallette() {
      return (
        this.isDrawingMission &&
        this.mode !== Mode.Welcome &&
        this.mode !== Mode.Results &&
        (this.mission.behavior === MissionType.DrawingEachTeam ||
          this.$store.state.drawing.assignedTeamId === this.user.teamID ||
          this.$store.state.drawing.assignedUserId === this.user.id ||
          this.isHost)
      )
    },
    transitionKey() {
      return this.mission?.activityId || this.mission?.id
    }
  },
  methods: {
    async handleSkipVideo() {
      try {
        await this.$confirm({
          message: `This action will skip the video for everyone. Continue?`
        })
        this.$_NotificationMixin_send({
          type: NotificationType.SKIP_VIDEO,
          scope: NotificationScope.GLOBAL
        })
        NavigationService.next(this.orgID, this.gameID)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.play-mode-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  &--presenter {
    .play-mode {
      flex-grow: 1;
      flex-basis: 100%;
      .mission {
        max-height: initial !important;
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }
  }

  .play-mode__countdown {
    margin-left: auto;
    margin-right: auto;
    height: 0;
    position: relative;
    z-index: 10;
    top: -16px;

    &--enlarged {
      transform: scale(1.5);
      top: 0;
    }
  }

  .play-mode__wrap {
    top: -16px;
    margin-left: auto;
    margin-right: auto;
    height: 0;
    position: relative;
    z-index: 10;
  }

  .canvas-tools-slide-transition-enter-active,
  .canvas-tools-slide-transition-leave-active {
    transition: all ease-in-out 0.35s;
    transform: translateX(0%);
  }
  .canvas-tools-slide-transition-enter,
  .canvas-tools-slide-transition-leave-to {
    transform: translateX(-105%);
    opacity: 0;
  }
  .draw-tool-btn {
    padding: 0px 4px;
    margin: 8px;
    &:not(.disabled):not(.active) {
      cursor: pointer;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }
    &.active {
      pointer-events: none;
      box-shadow: 0 0 0 1px currentColor;
    }
  }
  .pen-size {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 3px;
    }
    display: flex;
    .pen-size-svg {
      align-self: center;
      margin: auto;
    }
    .outline-circle {
      opacity: 0;
      stroke: $primary_accent_color;
    }
    &.active {
      .outline-circle {
        opacity: 1;
      }
    }
  }
  .color-picker {
    width: 100%;
    max-width: 170px;
    box-shadow: none !important;
    align-self: center;
    margin: auto;
  }

  .play-mode {
    position: relative;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      color: #b6d6ff;
    }
  }
  .mission {
    color: #fff;
    position: relative;
    z-index: 2;
    border: 1px solid rgba(255, 255, 255, 0.3);
    max-height: 300px;
    .disabled {
      pointer-events: none;
    }
  }

  .play-asset-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 300px;
  }
  .asset-column {
    position: relative;
  }
  .play-asset {
    overflow: hidden;
    background: radial-gradient(
      137.87% 137.87% at 49.24% 9.52%,
      #2c2c2c 0%,
      #000000 100%
    );
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(248, 249, 251, 0.33);
  }
}
</style>
