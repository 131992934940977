var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "play-mode-wrap",
      class:
        ((_obj = {}),
        (_obj["play-mode-wrap--presenter"] = _vm.isViewerPresenter),
        _obj),
      attrs: { "fill-height": "" },
    },
    [
      _c(
        "v-flex",
        {
          staticClass: "asset-column",
          attrs: {
            xs3: !_vm.enlargedUI,
            xs9: _vm.enlargedUI && _vm.gameStatus.media,
            "ml-2": "",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "justify-center": "", "fill-height": "" } },
            [
              _c(
                "div",
                { staticClass: "play-asset-wrap" },
                [
                  _c("AssetSlide", {
                    staticClass: "play-asset",
                    attrs: { mode: _vm.mode },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        {
          staticClass: "play-mode",
          attrs: { xs6: !_vm.enlargedUI, "mr-2": "", "ml-2": "" },
        },
        [
          _vm.isTimeAvailable
            ? _c(
                "div",
                {
                  staticClass: "play-mode__countdown",
                  class: { "play-mode__countdown--enlarged": _vm.enlargedUI },
                },
                [
                  _c("PillCountdown", {
                    staticClass: "play-mode__countdown-timer",
                    attrs: { endTime: _vm.endTime },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "MissionContainer",
            {
              attrs: {
                mode: _vm.mode,
                mission: _vm.mission,
                points: _vm.isMissionMeta,
              },
            },
            [
              _vm.isDrawingMission
                ? _c("Drawing", {
                    key: "drawing-component-" + _vm.missionID,
                    attrs: {
                      mission: _vm.mission,
                      height: _vm.DRAWING_CANVAS_HEIGHT,
                      width: 510,
                      bg: "#fff",
                    },
                  })
                : _vm._e(),
              _vm.missionType === _vm.MissionType.MultipleChoice
                ? _c("MultipleChoice", {
                    key: _vm.transitionKey,
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === _vm.MissionType.Poll
                ? _c("Poll", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === _vm.MissionType.RatingPoll
                ? _c("RatingPoll", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === _vm.MissionType.Giphy
                ? _c("Giphy", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === "Lipdub"
                ? _c("Lipdub", {
                    key: "lipdub-" + _vm.missionID,
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : [
                    _vm.MissionType.TeamPhoto,
                    _vm.MissionType.TakePhoto,
                  ].includes(_vm.missionType)
                ? _c("TakePhoto", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === "Photo"
                ? _c("Photo", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === _vm.MissionType.URL
                ? _c("URL", { attrs: { mode: _vm.mode, mission: _vm.mission } })
                : _vm.missionType === "Unlock"
                ? _c("Unlock", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.isSignUp
                ? _c("SignUp", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === "Text" ||
                  _vm.missionType === "Freeform" ||
                  _vm.missionType === "Team Name" ||
                  _vm.missionType === "Fact Match"
                ? _c("TextInput", {
                    attrs: {
                      mode: _vm.mode,
                      mission: _vm.mission,
                      maxTextLength:
                        _vm.missionType === "Team Name" ? 30 : null,
                    },
                  })
                : _vm.missionType === _vm.MissionType.Categories
                ? _c("Categories", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === _vm.MissionType.FamilyFeud
                ? _c("SurveySays", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === _vm.MissionType.YouTube
                ? _c("VideoMission", { attrs: { mission: _vm.mission } })
                : _vm.isTextOnly
                ? _c("TextOnly", {
                    attrs: { mode: _vm.Mode.Explain, mission: _vm.mission },
                  })
                : _vm.missionType === "Represent"
                ? _c("Represent", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === "Royal Rumble"
                ? _c("RoyalRumble", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === _vm.MissionType.Slides
                ? _c("Slides", { attrs: { mission: _vm.mission } })
                : _vm.missionType === _vm.MissionType.PhotoBooth
                ? _c("PhotoBooth", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === "Two Truths"
                ? _c("TwoTruths", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === "Award"
                ? _c("AwardVote", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.isStartButton || _vm.isOpenWeveStart
                ? _c("StartButton", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm.missionType === _vm.MissionType.QRCode
                ? _c("QRCode")
                : _vm.isTakeVideo
                ? _c("TakeVideo", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.skipButton
            ? _c(
                "div",
                {
                  staticClass: "play-mode__wrap skip-video",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSkipVideo()
                    },
                  },
                },
                [_c("RtbPillPurple", [_vm._v(" SKIP VIDEO ")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-flex",
        {
          attrs: {
            "d-flex": "",
            "align-center": "",
            xs3: !_vm.enlargedUI,
            "mr-2": "",
          },
        },
        [
          _c(
            "transition",
            {
              attrs: { name: "canvas-tools-slide-transition", mode: "out-in" },
            },
            [_vm.hasPallette ? _c("DrawingPicker") : _vm._e()],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }