<template>
  <transition name="slide-transition" mode="out-in">
    <CopyLinkSlide v-if="isOpenWeveStart" />
    <AssetMapper
      :mode="mode"
      :drawing="isDrawingMission"
      v-else-if="mediaToShow && mediaToShow !== 'none'"
    />
  </transition>
</template>

<script>
import { mapGetters } from "vuex"
import { Mission as MissionType, Mode } from "@shared/enums"
import Mission from "@shared/Mission"

export default {
  name: "AssetSlide",
  components: {
    AssetMapper: () => import("@/components/GroupTeams/Common/AssetMapper.vue"),
    CopyLinkSlide: () => import("@/components/GroupTeams/Common/CopyLinkSlide")
  },
  props: {
    mode: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters({ mission: "getCurrentMission" }),
    isOpenWeveStart() {
      return this.missionType === MissionType.OpenWeveStart
    },
    missionType() {
      return this.mission?.behavior
    },
    isDrawingMission() {
      return this.mode !== Mode.Results && Mission.isDrawing(this.mission)
    },
    mediaToShow() {
      if (this.missionType === MissionType.YouTube) return "none"
      return this.isDrawingMission
        ? "drawing"
        : this.$store.getters.gameStatus.media || "none"
    }
  }
}
</script>

<style scoped lang="scss">
.slide-transition-enter-active,
.slide-transition-leave-active {
  transition: all ease-in-out 0.35s;
  transform: translateX(0%);
}
.slide-transition-enter,
.slide-transition-leave-to {
  transform: translateX(105%);
  opacity: 0;
}
</style>
